<template>
  <div class="conOfChangePassPage fill-height">
    <v-form class="fill-height conOfLoginForm">
      <v-container fluid class="fill-height">
        <v-row class="fill-height d-flex justify-center">
      
          <v-col md="4" cols="12">
            <div class="formCard fill-height">
              <div class="conOfFormSection fill-height">
                <v-row>
                  <v-col cols="12 pb-2">
                                            <label class="labelPass">Old Password</label>

                    <v-text-field
                      outlined
                      class="formInput"
                      placeholder="Enter Old Password"
                      v-model="form.old_password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      required
                      :error-messages="passwordErrors"
                      @input="$v.form.old_password.$touch()"
                      @blur="$v.form.old_password.$touch()"
                      hide-details="auto"
                      solo
                    ></v-text-field>
                    <!-- persistent-placeholder -->
                  </v-col>
                  <v-col cols="12 pb-2">
                      <label class="labelPass">New Password</label>
                    <v-text-field
                      outlined
                      class="formInput"
                      placeholder="Enter New Password"
                      v-model="form.new_password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      required
                      :error-messages="passwordErrors"
                      @input="$v.form.new_password.$touch()"
                      @blur="$v.form.new_password.$touch()"
                      hide-details="auto"
                      solo
                    ></v-text-field>
                    <!-- persistent-placeholder -->
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <div class="conLoginFooter px-3">
                   
                      <div class="btnLoginCon">
                        <v-btn
                          class="primaryBtn loginBtn"
                          @click="changePassForm"
                          text
                          tile
                          :disabled="isLoading"
                          :loading="isLoading"
                        >
                          Submit
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" v-if="notvalidMessage">
                    <div class="vaidMessage">
                        {{msgSucces}}
                    </div>
                  </v-col>
                   <v-col cols="12" v-if="validMessage">
                    <div class="vaidMessage">{{apiMessage}}</div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
      
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();

export default {
  name: "changePass",
  mixins: [validationMixin],
  validations: {
    form: {
      old_password: { required },
      new_password: { required },
    },
  },
  data() {
    return {
      isLoading: false,
      validMessage: false,
            notvalidMessage: false,
            msgSucces:"",

        // email: "admin@admin.com",
        // password: "12345678",
      form: {
        old_password: "",
        new_password: "",
      },
      showPassword: false,
    };
  },
  methods: {
    changePassForm() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isLoading = true;
      apiServices.post("password/change", this.form).then((res) => {
        if (res) {
                      this.notvalidMessage = true;
          this.isLoading = false;
         this.msgSucces=res.message
         
        } else {
          this.validMessage = true;
          this.isLoading = false;
        }
      });
    },
  },
  computed: {
       ...mapGetters(["apiMessage"]),

    passwordErrors() {
      const errors = [];
      if (!this.$v.form.old_password.$dirty) return errors;
      !this.$v.form.old_password.required && errors.push("Password is required.");
      return errors;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/abstracts/variables";

.conOfChangePassPage {

  .conOfLoginForm {
    .conBollutsImg {
      img {
        max-width: 300px;
        width: 75%;
      }
    }

    .formCard {

      // border-radius: 0;
      // border: 0;
      // padding: 2rem;

      .conOfFormSection {
        max-width: 400px;
        display: flex;
        align-items: center;
        margin: auto;

        .loginHeader {
          text-align: center;
          img {
            width: 80%;
          }
        }

        .formInput {
          color: #242021;

          .v-input__slot {
            box-shadow: none !important;
          }
         .labelPass{
             font-size: 1rem;
    font-weight: 600;
    color: #1F2026;
         }
          // fieldset {
          //   box-shadow: none !important;
          //   border: 1px solid #D4D4D4;
          //   border-radius: 35px;
          // }

          label.v-label.v-label--active {
            left: -4px !important;
          }

          .v-icon {
            color: #a0a0a0;

            &.primary--text {
              color: #a0a0a0;
            }
          }
        }
      }

      .forgetPass {
        text-align: right;

        a {
          text-decoration: none;
          color: #FEBF2C;
          font-size: 1rem;
        }
      }

      .btnLoginCon {
        button.primaryBtn.loginBtn.v-btn {
          min-width: 100%;
          background: #FEBF2C;
          min-height: 60px;
          border-radius: 10px;
          color: #000000;
          font-size: 1rem;
          font-weight: bold;
        }
      }

    }


    .vaidMessage {
      color: $color-primary;
      font-size: .8rem;
      text-align: center;
    }

    .v-messages__message,
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      margin: 0;
    }

    .v-text-field .v-label {
      font-size: 1rem;
    }

    .isHaveAccount {
      // color: $color-text-gray125;
      font-size: 0.9rem;
      text-transform: capitalize;
    }
  }
}



// ::v-deep {
//   .conOfLoginForm .primary--text {
//     color: $color-text-primary !important;
//     caret-color: $color-text-primary !important;
//   }

//   .v-text-field--outlined.v-input--is-focused fieldset, .v-text-field--outlined.v-input--has-state fieldset {
//     border: 2px solid #FEBF2C;
// }
// }
</style>
